import React, { ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SingleValue } from "react-select";

import Button from "../../../../components/Button";
import Dropdown from "../../../../components/Dropdown";
import { MyOptionType } from "../../../../components/Dropdown/handleStyles";
import Input from "../../../../components/Input";
import TextArea from "../../../../components/TextArea/textarea";
import { updateBlockById, uploadSvg } from "../../../../services/apiRequests";
import {
  selectSelectedBlock,
  setSelectedBlockInstance,
  updateBlock,
} from "../../../../store/blocksSlice";
import {setShowDialog} from "../../../../store/commonSlice";
import {
  selectAbbreviationsOptions,
  selectBlockTypesOptions,
  selectEquipmentClassesOptions,
} from "../../../../store/optionsSlice";

import "./style.scss";

const Parameters: React.FC = () => {
  const dispatch = useDispatch();
  const blockTypesOptions = useSelector(selectBlockTypesOptions);
  const abbreviationsOptions = useSelector(selectAbbreviationsOptions);
  const equipmentClassesOptions = useSelector(selectEquipmentClassesOptions);
  const selectedBlock = useSelector(selectSelectedBlock);
  const imageInputRef = React.createRef<HTMLInputElement>();

  const onImageUploadClick = () => {
    if (imageInputRef.current) imageInputRef.current.click();
  };

  const onChangeFile = async (event: ChangeEvent<HTMLInputElement>) => {
    if (
      event.target?.files?.length &&
      event.target?.files?.length > 0 &&
      selectedBlock?.id
    ) {
      if (event.target?.files[0].type === "image/svg+xml") {
        const formData = new FormData();
        formData.append("file", event.target?.files[0] as Blob);
        try {
          const response = await uploadSvg(
            selectedBlock?.id?.toString(),
            formData
          );
          if (response.data && selectedBlock.instance) {
            const objToUpdate = {
              ...selectedBlock.instance,
              user_svg: response.data.user_svg,
              input_connection_points: response.data.input_connection_points,
              out_connection_points: response.data.out_connection_points,
            };
            dispatch(setSelectedBlockInstance(objToUpdate));
            dispatch(updateBlock(objToUpdate));
          }
        } catch (err) {
          //
        }
      } else {
        dispatch(
          setShowDialog({
            objectType: "FILE",
            dialogType: "ERROR",
            details: "Выбран неверный тип файла",
          })
        );
      }
    }
  };

  const onChangeBlockParameter = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    param: "name" | "abbreviation" | "description"
  ) => {
    if (selectedBlock?.instance) {
      dispatch(
        setSelectedBlockInstance({
          ...selectedBlock.instance,
          [param]: e.target.value,
        })
      );
    }
  };

  const saveBlockParameter = async (
    param: "name" | "abbreviation" | "description"
  ) => {
    if (selectedBlock?.id) {
      try {
        await updateBlockById(selectedBlock.id.toString(), {
          [param]: selectedBlock.instance?.[param],
        });
        if (selectedBlock.instance)
          dispatch(updateBlock(selectedBlock.instance));
      } catch (e) {
        //
      }
    }
  };

  const onOptionChange = async (
      option: SingleValue<MyOptionType>,
      param:
          | "block_type"
          | "abbreviation"
          | "equipment_class_id"
  ) => {
    if (option?.value && selectedBlock.instance && selectedBlock.id) {
      try {
        const blockSchemaParam = (param == "equipment_class_id") ? "equipment_class" : param
        const objToUpdate = {
          ...selectedBlock.instance,
          [blockSchemaParam]: option?.value,
        };
        dispatch(setSelectedBlockInstance(objToUpdate));
        await updateBlockById(selectedBlock.id.toString(), {
          [param]: option?.value,
        });
        dispatch(updateBlock(objToUpdate));
      } catch (e) {
        //
      }
    }
  };

  const onInputClick = (event: React.MouseEvent<HTMLInputElement>) => {
    (event.target as HTMLInputElement).value = "";
  };

  return (
    <div className="parameters__container">
      {selectedBlock?.id && (
        <>
          <div className="parameters__title">Параметры</div>
          <>
            <span className="input-label">Графическое обозначение</span>
            <div className="parameters__image-row">
              {selectedBlock.instance?.user_svg?.file_name ? (
                <>
                  <object
                    data={`https://api-new.sys-eng.numdes.com/attachments/user_svg/${selectedBlock.instance?.user_svg?.file_name}`}
                    type="image/svg+xml"
                    className="parameters__image"
                  />
                </>
              ) : (
                <div className="parameters__image">Файл не загружен</div>
              )}
              <Button
                mode="secondary"
                className="parameters__image-btn"
                onClick={onImageUploadClick}
              >
                Загрузить .svg файл
              </Button>
              <input
                ref={imageInputRef}
                className="parameters__image-input"
                type="file"
                accept={".svg"}
                multiple={false}
                onChange={onChangeFile}
                onClick={onInputClick}
              />
            </div>
          </>
          <div className="block-parameters-row">
            <div className="block-parameters-element">
              <Input
                label="Название"
                value={selectedBlock?.instance?.name}
                onChange={(e) => onChangeBlockParameter(e, "name")}
                onBlur={() => saveBlockParameter("name")}
              />
            </div>
            <div className="block-parameters-element">
              <Dropdown
                placeholder=""
                label="Код (ГенПлан)"
                options={abbreviationsOptions}
                isSearchable={false}
                value={abbreviationsOptions?.find(
                  (item) => item.value === selectedBlock?.instance?.abbreviation
                )}
                onChange={(e) => onOptionChange(e, "abbreviation")}
              />
            </div>
          </div>
          <div className="block-parameters-row">
            <div className="block-parameters-element">
              <Dropdown
                placeholder=""
                label="Тип"
                options={blockTypesOptions}
                isSearchable={false}
                value={blockTypesOptions?.find(
                  (item) => item.value === selectedBlock?.instance?.block_type
                )}
                onChange={(e) => onOptionChange(e, "block_type")}
              />
            </div>
            <div className="block-parameters-element">
              <Dropdown
                placeholder=""
                label="Класс"
                isSearchable={false}
                options={equipmentClassesOptions}
                value={equipmentClassesOptions?.find(
                  (item) =>
                    item.value ===
                    selectedBlock?.instance?.equipment_class?.id?.toString()
                )}
                onChange={(e) => onOptionChange(e, "equipment_class_id")}
              />
            </div>
          </div>
          <div className="block-parameters-row">
            <TextArea
              label="Функция блока"
              placeholder="Описание функции"
              value={selectedBlock?.instance?.description}
              onChange={(e) => onChangeBlockParameter(e, "description")}
              onBlur={() => saveBlockParameter("description")}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Parameters;
